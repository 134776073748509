"use client";
import React from "react";
import AnimateIn from "./AnimateIn";
import { cn } from "@/app/_utils/ui";

const FadeIn = ({
  children,
  delay,
  className,
  animateToClassname,
  animateFromClassname,
}: {
  children: React.ReactNode;
  delay?: number;
  className?: string;
  animateFromClassname?: string;
  animateToClassname?: string;
}) => {
  return (
    <AnimateIn
      className={className}
      animateFromClassname={cn("opacity-0", animateFromClassname)}
      animateToClassname={cn("opacity-100", animateToClassname)}
      delay={delay}
    >
      {children}
    </AnimateIn>
  );
};

export default FadeIn;
