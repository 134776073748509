import { useState, useEffect } from "react";

function useSidebarCollapse() {
  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem("sidebarCollapsed") === "true",
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setIsCollapsed(localStorage.getItem("sidebarCollapsed") === "true");
    };

    window.addEventListener("sidebarCollapse", handleStorageChange);

    return () => {
      window.removeEventListener("sidebarCollapse", handleStorageChange);
    };
  }, []);

  return isCollapsed;
}

export default useSidebarCollapse;
