import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../reducers/store";
import { Dialog, DialogTrigger, DialogContent } from "../dialog";
import { fetchHuddleGroups } from "../../reducers/huddleSlice";
import { CameraOutlined, CheckCircleOutlined } from "@ant-design/icons";
import AddHuddleImageForm from "./AddHuddleImageForm";
import { Button } from "../buttons/button";
import { AddHuddlePicIcon } from "../graphics/huddle";

const AddHuddleImageDialog = ({
  open,
  setOpen,
  label,
  children,
}: {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  label?: string;
  children?: React.ReactNode;
}) => {
  const [isUploadComplete, setIsUploadComplete] = useState(false);

  const { huddleGroups, isLoading } = useSelector(
    (state: RootState) => state.huddleGroups,
  );
  const accessToken = useSelector(
    (state: RootState) => state.login.accessToken,
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (open && accessToken) {
      dispatch(fetchHuddleGroups(accessToken));
      setIsUploadComplete(false);
    }
  }, [open, accessToken]);

  const joinedHuddles = huddleGroups
    .filter((huddle) => huddle.UserJoined)
    .sort(
      (a, b) =>
        new Date(b.huddleTime).getTime() - new Date(a.huddleTime).getTime(),
    )
    .map((huddle) => ({
      huddleTime: huddle.huddleTime,
      huddleTimeLocal: huddle.localHuddleTime,
      huddleId: huddle.huddleId,
      Location: huddle.Location,
    }));

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {children ? (
          children
        ) : (
          <Button id="addHuddleImage" className="w-full" variant="blue">
            <CameraOutlined className={label ? "mr-1" : ""} /> {label}
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="max-w-[540px]">
        <div
          id="addHuddleImageDialog"
          className="flex flex-col gap-4 items-center justify-center pt-4 px-8 pb-8"
        >
          <div className="w-[45px] h-[45px] flex justify-center items-center rounded-full bg-red-secondary">
            <AddHuddlePicIcon className="text-white" />
          </div>
          <div className="text-lg -mt-4">Add Huddle Picture</div>
          {joinedHuddles.length === 0 && !isLoading ? (
            <div className="text-center">
              <p className="mt-4 font-semibold">No recent huddles.</p>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                variant="outline"
                className="mt-8"
              >
                Cancel
              </Button>
            </div>
          ) : joinedHuddles.length === 1 ? (
            <AddHuddleImageForm
              onImageUploadSuccess={() => {
                setIsUploadComplete(true);
              }}
              huddle={joinedHuddles[0]}
              showHuddleInfo={true}
            />
          ) : (
            joinedHuddles.map((huddle, index) => (
              <React.Fragment key={huddle.huddleId + huddle.huddleTime}>
                {index === 0 && (
                  <>
                    <AddHuddleImageForm
                      onImageUploadSuccess={() => {
                        setIsUploadComplete(true);
                      }}
                      huddle={huddle}
                      showHuddleInfo={true}
                    />
                    <hr className="my-6 border-neutral-400" />
                    <p className="text-neutral-700">Previous huddles</p>
                  </>
                )}
                {index !== 0 && (
                  <AddHuddleImageForm huddle={huddle} showHuddleInfo={true} />
                )}
              </React.Fragment>
            ))
          )}
          {isUploadComplete && (
            <div
              id="imageUploadSuccess"
              className="flex flex-col items-center justify-center py-4 gap-4"
            >
              <div className="flex items-center justify-center">
                <CheckCircleOutlined className="scale-110 mr-2" /> Image
                Uploaded
              </div>
              <Button onClick={() => setOpen(false)} variant="outline">
                Close
              </Button>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddHuddleImageDialog;
